<template>
    <div>
        <div id="printTest">
            <div style="font-size: 16px;width:100%" v-loading="loading">
                <div style="text-align: center;padding-bottom:20px;font-size: 20px">
                    <h1 style="margin:0px;">评审专家抽取情况登记表</h1>
                </div>
                <el-row class="border-left">
                    <el-col :span="6" class="border td">项目名称</el-col>
                    <el-col :span="18" class="border td">{{form.project.name}}</el-col>
                </el-row>
                <el-row class="border-left">
                    <el-col :span="6" class="border td">审评时间</el-col>
                    <el-col :span="18" class="border td">{{ form.project.startTime | formatDate }}</el-col>
                </el-row>
                <el-row class="border-left">
                    <el-col :span="24" class="border td">评审专家抽取情况</el-col>
                </el-row>
                <el-row class="border-left border-bottom">
                    <el-row>
                        <el-col :span="2" class="border">序号</el-col>
                        <el-col :span="4" class="border">姓名</el-col>
                        <el-col :span="6" class="border">科室</el-col>
                        <el-col :span="6" class="border">联系方式</el-col>
                        <el-col :span="6" class="border">备注</el-col>
                    </el-row>
                    <el-row v-for="(item, index) in form.result.random.concat(form.preferred)" :key="index" type="flex">
                        <el-col :span="2" class="border td">{{ index + 1 }}</el-col>
                        <el-col :span="4" class="border td">{{ item.name }}</el-col>
                        <el-col :span="6" class="border td">{{ item.department }}</el-col>
                        <el-col :span="6" class="border td">{{ item.contact }}</el-col>
                        <el-col :span="6" class="border td">{{ form.project.remarks}}</el-col>
                    </el-row>
                </el-row>
                <el-row style="padding: 20px 0">
                    <el-col :span="18"> 抽取人：</el-col>
                    <el-col :span="6"> 监督人：</el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "chooseModelTwo",
        props: {
            form: {
                type: [Object],
                default: () => {}
            },
            loading: {
                default: false
            },
        },
        data() {
            return {
            }
        },
    }
</script>

<style lang="scss" scoped>
    .border {
        text-align: center;
        border-top: 1px solid #8c939d;
        border-right: 1px solid #8c939d;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }
    .border-left{
        border-left: 1px solid #8c939d;
    }
    .border-bottom{
        border-bottom: 1px solid #8c939d;
    }
    .first {
        display: initial !important;
    }

    .bz {
        display: block;
        font-size: 0.67em;
        margin-block-start: 0.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold
    }

    .td {
        height: 40px;
        padding:0 10px;
    }

    #printTest {
        // display: inherit;
        width: 100%;
    }

    @media print {
        #printTest {
            width: 100%;
            max-width: 100%;
        }
    }
</style>
