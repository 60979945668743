<template>
    <div class="page">
        <div class="page-header ">
            <el-page-header @back="$router.go(-1)" content="专家抽取记录表" />
        </div>
        <div class="page-main">
            <div class="main" style="height:100%;overflow: scroll;">
                <div style="background-color: white;max-width: 800px;min-width: 600px;margin:30px auto;padding:20px 30px;">
                    <div id="printTest">
                        <choose-model-two :form="form" :loading="loading"></choose-model-two>
                    </div>
                </div>
            </div>
            <div class="side hidden-sm-and-down" >
                <div class="buttons">
                    <el-button class="btn" type="primary" v-print="'#printTest'">打印报告</el-button>
                </div>
                <div class="buttons">
                    <el-button class="btn" type="default" @click="getData">刷新</el-button>
                </div>
                <div class="buttons" style="position:absolute;bottom:40px;left:0;right:0">
                    <el-button class="btn" type="danger" @click="handleDelete">删除记录</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ChooseModelTwo from "./widgets/chooseModelTwo";
export default {
    name: 'chlogView',
    components: {ChooseModelTwo},
    data() {
        return {
            loading: false,
            form: {
                project: {},
                chooseCount: '',
                expertCount: '',
                category: [],
                department: [],
                major: [],
                title: [],
                education: [],
                preferred: [],
                avoided: [],
                result: {
                    random: []
                },
            }
        }
    },
    methods: {
        handleDelete() {
            this.$confirm(`确认要删除 ${this.form.project.name} 吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.delete(`/choose/${this.form.id}`)
                    .then(resp => {
                        this.$message[resp.data.status](resp.data.msg)
                        this.$router.push('/main/choose/chlog')
                    })
            }).catch(() => {
                // 用户点击取消
                // 在这里可以取消选择
            });
        },
        getData() {
            this.loading = true
            this.$http.get(`/choose/${this.$route.params.id}`)
                .then(resp => {
                    this.form = resp.data
                }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-main {
        margin-top: 20px;
        flex: 1;
        overflow: scroll;
        display: flex;

        .main {
            background-color: #ddd;
            flex: 1;
        }

        .side {
            width: 200px;
            text-align: center;
            padding: 20px;
            position: relative;

            .buttons {
                margin: 20px;

                .btn {
                    width: 120px;
                }
            }
        }
    }
}

.border {
    text-align: center;
    border: 1px solid #8c939d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.first {
    display: initial !important;
}

.bz {
    display: block;
    font-size: 0.67em;
    margin-block-start: 0.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold
}

.td {
    height: 30px;
}

#printTest {
    // display: inherit;
    width: 100%;
}

@media print {
    #printTest {
        width: 100%;
        max-width: 100%;
    }
}
</style>
